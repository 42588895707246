/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as Icons from "@goodgym/icons";
import * as NP from "@goodgym/noun-project";
import _ from "lodash";
import React from "react";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import ThreePIcon from "@mui/icons-material/ThreeP";

export type TaskforceRoleSelectProps = {
  onClose: React.MouseEventHandler;
  back: () => any;
  next: (roleIds: T.TaskforceRoleFragment["id"][] | "NONE") => any;
  session: T.SessionCardFragment & {
    taskforceRoles: T.TaskforceRoleFragment[];
  };
};

const TaskforceRoleSelect: React.FC<
  React.PropsWithChildren<TaskforceRoleSelectProps>
> = ({ session, next, back, onClose }) => {
  const [selectedRoles, setSelectedRoles] = React.useState<
    T.TaskforceRoleFragment[]
  >([]);

  return (
    <>
      <UI.DialogTitle>
        Helping out
        <UI.CloseButton onClick={onClose} />
      </UI.DialogTitle>
      <UI.DialogContent sx={{ mb: 0, pb: 0 }}>
        <UI.Typography sx={{ mb: 1 }}>
          You're signing up to <strong>{session.title}</strong>.
        </UI.Typography>
        <UI.Typography sx={{ mb: 1 }}>
          There are some additional ways you can help out if you like.
          Optionally choose one of these roles:
        </UI.Typography>
        {session.taskforceRoles.map((r) => {
          const Icon = (() => {
            switch (r.slug) {
              case "photographer":
                return NP.Camera;
              case "back-marker":
                return NP.Running;
              case "walk-leader":
                return NP.Running;
              case "report-writer":
                return NP.Report;
              case "document-checker":
                return Icons.VerifiedUser;
              case "welcomer":
                return EmojiPeopleIcon;
              case "social-media":
                return ThreePIcon;
              case "welcomer":
                return EmojiPeopleIcon;
              case "social-media":
                return ThreePIcon;
              default:
                return Icons.Person;
            }
          })();

          return (
            <UI.ListItem
              key={r.id}
              component="label"
              htmlFor={r.id}
              disableGutters
              sx={{ cursor: "pointer" }}
            >
              <UI.ListItemIcon sx={{ mr: 2 }}>
                <UI.Avatar style={{ fontSize: "28px" }}>
                  <Icon style={{ fill: "#fff" }} />
                </UI.Avatar>
              </UI.ListItemIcon>
              <UI.ListItemText primary={r.name} secondary={r.description} />
              <UI.Checkbox
                id={r.id}
                sx={{ ml: 2 }}
                checked={selectedRoles.includes(r)}
                onChange={(e) =>
                  setSelectedRoles(
                    e.target.checked
                      ? _.concat(selectedRoles, r)
                      : _.without(selectedRoles, r)
                  )
                }
              />
            </UI.ListItem>
          );
        })}
      </UI.DialogContent>
      <UI.DialogActions sx={{ mt: 0, pt: 0, m: 2 }}>
        <UI.Button variant="text" color="neutral" fullWidth onClick={back}>
          Back
        </UI.Button>
        <UI.Button
          color="secondary"
          fullWidth
          onClick={() =>
            next(
              selectedRoles.length === 0
                ? "NONE"
                : selectedRoles.map((r) => r.id)
            )
          }
        >
          Next
        </UI.Button>
      </UI.DialogActions>
    </>
  );
};

export default TaskforceRoleSelect;
